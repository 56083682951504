import type { MaybeRef } from 'vue'
import { ref } from 'vue'
import { array, number, object, string } from 'yup'
import { isEmpty } from 'lodash-es'
export type parseParamsType = {
  sort?: 'alphabetical' | 'rating' | null | undefined
  of?: string | null
  to?: string | null
  ra: (number | null | undefined)[]
  ti: string | null
  na: string[]
  kw: string[]
  co: string[]
  genre: number[]
}
const addChips = (model: globalThis.Ref<string[]>, chipsRef: MaybeRef) => {
  const inputElement = chipsRef.value?.$el.querySelector('input')
  if (model.value?.length < 3) {
    if (model.value !== null && chipsRef?.value.inputValue !== null) {
      model.value = [...model.value, chipsRef.value.inputValue].filter((el) => !isEmpty(el))
      chipsRef.value.inputValue = null
    }
  }
  inputElement.value = null
}
export const useAdvancedSearchFilters = () => {
  const localePath = useLocalePath()
  const selectedTitleSearch = ref<string | null>(null)
  const selectedNameSearch = ref<string[]>([])
  const selectedKeywordSearch = ref<string[]>([])
  const selectedYearOfFilters = ref<string | null>(null)
  const selectedYearToFilters = ref<string | null>(null)
  const selectedRatingFilters = ref<number[]>([])
  const selectedCountryFilters = ref<string[]>([])
  const selectedGenreFilters = ref<number[]>([])
  const selectedSortBy = ref<string | null>(null)

  const URLParamsSchema = object({
    ra: array().of(number().min(1).max(7).notRequired()).default([]).ensure(),
    of: string().notRequired().nullable(),
    to: string().notRequired().nullable(),
    na: array().of(string().required()).default([]).ensure(),
    kw: array().of(string().required()).default([]).ensure(),
    co: array().of(string().required()).default([]).ensure(),
    genre: array().of(number().required()).default([]).ensure(),
    sort: string().oneOf(['alphabetical', 'rating', 'year']).nullable()
  })

  const resetFilters = () => {
    selectedTitleSearch.value = null
    selectedNameSearch.value = []
    selectedKeywordSearch.value = []
    selectedYearOfFilters.value = null
    selectedYearToFilters.value = null
    selectedRatingFilters.value = []
    selectedCountryFilters.value = []
    selectedGenreFilters.value = []
    selectedSortBy.value = null
  }

  const search = () => {
    return navigateTo(
      localePath({
        name: 'advanced-search',
        query: {
          ...(selectedTitleSearch.value && { ti: selectedTitleSearch.value }),
          ...(selectedNameSearch.value.length && { na: selectedNameSearch.value }),
          ...(selectedKeywordSearch.value.length && { kw: selectedKeywordSearch.value }),
          ...(selectedYearOfFilters.value && { of: selectedYearOfFilters.value }),
          ...(selectedYearToFilters.value && { to: selectedYearToFilters.value }),
          ...(selectedRatingFilters.value.length && { ra: selectedRatingFilters.value }),
          ...(selectedCountryFilters.value.length && { co: selectedCountryFilters.value }),
          ...(selectedGenreFilters.value.length && { genre: selectedGenreFilters.value }),
          ...(selectedSortBy.value && { sort: selectedSortBy.value })
        }
      }),
      { replace: true }
    )
  }
  const attributesToSearchOn = () => {
    const titlesNotEmpty =
      selectedTitleSearch.value !== null && selectedTitleSearch.value?.length > 0
    const keywordsNotEmpty = selectedKeywordSearch.value.length > 0
    const contactsNotEmpty = selectedNameSearch.value.length > 0
    const attributes = []
    if (titlesNotEmpty) {
      attributes.push('all_titles')
    }

    if (keywordsNotEmpty) {
      attributes.push('keywords')
    }

    if (contactsNotEmpty) {
      attributes.push('contacts')
    }

    if (!titlesNotEmpty && !keywordsNotEmpty && !contactsNotEmpty) {
      attributes.push('keywords')
    }

    return attributes
  }

  const setSelectedValues = (parsedParams: parseParamsType) => {
    selectedTitleSearch.value = parsedParams?.ti ?? null
    selectedRatingFilters.value = parsedParams?.ra ?? []
    selectedNameSearch.value = parsedParams?.na ?? []
    selectedKeywordSearch.value = parsedParams?.kw ?? []
    selectedYearOfFilters.value = parsedParams?.of ? `${parsedParams?.of}` : null
    selectedYearToFilters.value = parsedParams?.to ? `${parsedParams?.to}` : null
    selectedGenreFilters.value = parsedParams?.genre ?? []
    selectedCountryFilters.value = parsedParams?.co ?? []
    selectedSortBy.value = parsedParams?.sort ?? null
  }
  const moviesQuery = () => {
    const meiliQuery = {
      contacts: [],
      keywords: [],
      all_titles: []
    }

    const titles = selectedTitleSearch.value as unknown as string
    const contacts = selectedNameSearch.value as unknown as Array<string>

    const keywords = selectedKeywordSearch.value as unknown as Array<string>

    if (titles && titles.length) {
      meiliQuery.all_titles.push(titles)
    }
    if (keywords && keywords.length) {
      keywords.forEach((c) => meiliQuery.keywords.push(`${c}`))
    }
    if (contacts && contacts.length) {
      contacts.forEach((c) => meiliQuery.contacts.push(`${c}`))
    }

    return meiliQuery
  }

  const sortByFilter = () => {
    if (selectedSortBy.value == 'alphabetical') {
      return 'title:asc'
    } else if (selectedSortBy.value === 'rating') {
      return 'cote:asc'
    } else if (selectedSortBy.value === 'year') {
      return 'year:desc'
    }
  }
  const moviesFilter = () => {
    const meiliFilter: Array<string | Array<string>> = []
    const yearOf =
      selectedYearOfFilters.value != null ? parseInt(selectedYearOfFilters.value) : false
    const yearTo =
      selectedYearToFilters.value != null ? parseInt(selectedYearToFilters.value) : false
    const ratings = selectedRatingFilters.value as unknown as Array<string>
    const genres = selectedGenreFilters.value as unknown as Array<string>
    const countries = selectedCountryFilters.value as Array<string>

    if (ratings && ratings.length) {
      meiliFilter.push(ratings.map((c) => `cote = ${c}`))
    }

    if (genres && genres.length) {
      meiliFilter.push(genres.map((g) => `genre_ids = "${g}"`))
    }

    if (countries && countries.length) {
      meiliFilter.push(countries.map((c) => `countries = "${c}"`))
    }

    if (yearOf && !yearTo) {
      meiliFilter.push(`year >= ${yearOf}`)
    } else if (!yearOf && yearTo) {
      meiliFilter.push(`year  <= ${yearTo}`)
    } else if (yearOf && yearTo) {
      meiliFilter.push(`year <= ${yearTo}  AND year >= ${yearOf}`)
    }

    return meiliFilter as Array<string>
  }

  return {
    selectedTitleSearch,
    selectedNameSearch,
    selectedKeywordSearch,
    selectedYearOfFilters,
    selectedYearToFilters,
    selectedRatingFilters,
    selectedCountryFilters,
    selectedGenreFilters,
    selectedSortBy,
    URLParamsSchema,
    attributesToSearchOn,
    setSelectedValues,
    moviesFilter,
    addChips,
    moviesQuery,
    sortByFilter,
    resetFilters,
    search
  }
}
